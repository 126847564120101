import { Outlet, useLocation } from "react-router-dom";
import TextViewer from "../components/TextViever";

const Offer = () => {
  const location = useLocation();

  const diplay = location.pathname.split("/").pop() === "oferta";
  console.log(diplay);
  if (diplay) {
    return <TextViewer obszar="offer" />;
  }
  return <Outlet />;
};

export default Offer;

import { Link } from "react-router-dom";
import logo from "../img/logo.png";

const Navbar = () => {
  return (
    <nav className="py-10 min-w-full h-fit px-12">
      <Link to="/">
        <img src={logo} width={"249"} alt="Windprojekt" />
      </Link>
    </nav>
  );
};

export default Navbar;

import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../layout/RootLayout";
import Home from "../pages/Home";
import Offer from "../pages/Offer";
import About from "../pages/About";
import WhyUs from "../pages/about/WhyUs";
import Message from "../pages/about/Message";
import Scope from "../pages/about/Scope";
import Realizations from "../pages/Realizations";
import Parks from "../pages/realizations/Parks";
import Contat from "../pages/Contact";
import Investor from "../pages/offer/Investor";
import Land from "../pages/offer/Land";
import Cooperation from "../pages/offer/Cooperation";

export const routes = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
        children: [
          {
            path: "us",
            element: <WhyUs />,
          },
          {
            path: "przeslanie",
            element: <Message />,
          },
          {
            path: "zakres",
            element: <Scope />,
          },
        ],
      },
      {
        path: "oferta",
        element: <Offer />,
        children: [
          {
            path: "inwestor",
            element: <Investor />,
          },
          {
            path: "grunty",
            element: <Land />,
          },
          {
            path: "wspolpraca",
            element: <Cooperation />,
          },
        ],
      },
      {
        path: "realizacje",
        element: <Realizations />,
        children: [
          {
            path: "wiatraki",
            element: <Parks />,
          },
        ],
      },
      {
        path: "kontakt",
        element: <Contat />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

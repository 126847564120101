import { useTranslation } from "react-i18next";

const languages = [
  {
    code: "pl",
    lang: "PL",
  },
  {
    code: "en",
    lang: "EN",
  },
  {
    code: "de",
    lang: "DE",
  },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="flex flex-row">
      {languages.map((lng) => (
        <div
          key={lng.code}
          className={
            lng.code === i18n.language
              ? "[&>button]:bg-white border-white text-primary m-1"
              : "[&>button]:bg-primary border-primary m-1"
          }
        >
          <button
            className="rounded-full border-4 border-inherit w-8 h-8"
            onClick={() => changeLanguage(lng.code)}
          >
            {lng.code.toString().toUpperCase()}
          </button>
        </div>
      ))}
    </div>
  );
};

export default LanguageSelector;

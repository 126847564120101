import { Navigate, Outlet, useLocation } from "react-router-dom";

const About = () => {
  const loc = useLocation();
  return (
    <>
      {loc.pathname.endsWith("/about") && <Navigate to="/about/przeslanie" />}
      <Outlet />
    </>
  );
};

export default About;

import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./router/Routes";
import "./i18n";

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
